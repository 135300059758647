<template>
  <div>
    <ch-layout page button>
      <template #button>
        <ch-button @click="openAdd">新增</ch-button>
      </template>
      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{width: '100%', height: '100%', size: 'mini'}">
          <template v-slot:handle="scope">
            <ch-button type="link" @click="openWatch(scope.row.id)">详情</ch-button>
            <ch-button type="link" @click="openEdit(scope.row.id)">编辑</ch-button>
            <ch-button type="link" :loading="sendCodeLoading" @click="openSendCode(scope.row.id)">发码</ch-button>
            <ch-button type="link" @click="openDetail(scope.row.id)">明细</ch-button>
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
            type="old"
            ref="page" :sizeDe="20"
            :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
    <!-- 新增 编辑 -->
    <modal ref="modal" :get-list-function="getTableList"/>
    <detailTable ref="detailTable" :get-list-function="getTableList"/>
  </div>
</template>

<script>
import Modal from "./modal";
import detailTable from "./detailTable.vue";
export default {
  components: {
    Modal,detailTable
  },
  data(){
    return{
      tableList: [], // table列表
      isTableLoading: false, // table加载
      sendCodeLoading: false, // 发码加载
      treeSelect:{
        add: false,
        edit: false,
        sendCode: false,
        detailTable: false,
      }
    }
  },
  computed:{
    tableOption:function (){
      return [
        { column: "type", prop: "", type: "selection", width: "25px" },
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text", label: "优惠码名称", prop: "name", showOverflowTooltip: true,},
        { column: "text", label: "有效期(天)", prop: "validDays", showOverflowTooltip: true,},
        { column: "text", label: "数量", prop: "totalQuantity", showOverflowTooltip: true,},
        { column: "text", label: "剩余数量", prop: "remainingQuantity", showOverflowTooltip: true,},
        { column: "text", label: "优惠金额(元)", prop: "amount", showOverflowTooltip: true,},
        { column: "text", label: "创建时间", prop: "createTime", showOverflowTooltip: true,},
        { column: "slot", label: "操作", slotName: "handle", width: "160" },
      ];
    },
  },
  mounted() {
    this.getTableList()
  },
  methods:{
    // 列表数据
    async getTableList() {
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/hmCoupon/list").then((res) => {
        this.tableList = res.rows
      }).finally(() => {this.isTableLoading = false});
    },
    // 新增
    openAdd(){
      this.$refs.modal.openAdd()
    },
    // 编辑
    openEdit(id){
      this.$refs.modal.openEdit(id)
    },
    openWatch(id){
      this.$refs.modal.openWatch(id)
    },
    openSendCode(id){
      this.$confirm('确定发放优惠码吗？','提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.sendCodeLoading = true;
        this.$curl.get("/hm/hmCoupon/sendCode/" + id).then(() => {
          this.$message.success('发放成功,点击明细查看发放记录')
          this.getTableList()
        }).finally(() => {
          this.sendCodeLoading = false;
        })
      })
    },
    openDetail(id){
      this.$refs.detailTable.openModal(id)
    }
  }
}
</script>

<style scoped>

</style>