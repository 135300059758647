<template>
  <el-dialog
      :title="title"
      :visible.sync="isOpenModal"
      :close-on-click-modal="false"
      width="1000px"
      @closed="closeModal">
    <div style="width: 95%;text-align: center">
      <ch-search
          :render-option="searchOption" :model="searchForm"
          :props="{ labelWidth: '70px'}">
      </ch-search>
      <ch-table :render-option="tableOption" :data="tableData"
                :loading="isTableLoading"
                :props="{width: '100%', height: '200px', maxHeight: '400px', size: 'mini'}">
      </ch-table>
      <ch-page
          type="old"
          ref="page" :sizeDe="20"
          :getListFunction="getTableList"
      ></ch-page>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      modalForm: {},
      isOpenModal: false,
      title: '明细',
      searchForm: {
        couponCode: "",
        couponId: null,
        orderNo: "",
        sendCouponUserName: "",
        userName: "",
        createBy: "",
        sendEndTime: "",
        sendStartTime: "",
        useEndTime: "",
        useStartTime: "",
        sendTime: [],
        useTime: [],
      },
      tableData: [],
      isTableLoading: false,
      couponId: null,
    }
  },
  computed: {
    searchOption:function(){
      return [
        { label: "券码", prop: "couponCode", type: "input", placeholder: "请输入券码" },
        { label: "用户", prop: "userName", type: "input", placeholder: "请输入用户ID", labelWidth: "40px" },
        { label: "订单号", prop: "orderNo", type: "input", placeholder: "请输入订单号", },
        { label: "发码人", prop: "createBy", type: "input", placeholder: "请输入发码人", },
        { label: "发码时间", prop: "sendTime", type: "time", placeholder: "请选择发码时间", },
        { label: "使用时间", prop: "useTime", type: "time", placeholder: "请选择使用时间", },
        {type: 'button', button: [
            {text: '查询', onClick: this.getTableList},
            {text: '重置', onClick: this.getTableList},
          ]},
      ]
    },
    tableOption:function (){
      return [
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text", label: "券码", prop: "code", showOverflowTooltip: true,},
        { column: "text", label: "优惠金额", prop: "amount", showOverflowTooltip: true,},
        { column: "text", label: "发码时间", prop: "createTime", showOverflowTooltip: true,},
        { column: "text", label: "使用时间", prop: "useTime", showOverflowTooltip: true,},
        { column: "text", label: "到期时间", prop: "expiredAt", showOverflowTooltip: true,},
        { column: "text", label: "用户", prop: "nickname", showOverflowTooltip: true,},
        { column: "text", label: "订单号", prop: "orderNumber", showOverflowTooltip: true,},
        { column: "text", label: "发码人", prop: "createBy", showOverflowTooltip: true,},
        { column: "text", label: "状态", prop: "_useStatus", showOverflowTooltip: true,},
      ];
    },
  },
  methods: {
    openModal(id) {
      this.isOpenModal = true
      this.title = '明细'
      this.searchForm.couponId = id
      this.$nextTick(() => {
        this.getTableList()
      })
    },
    getTableList() {
      this.isTableLoading = true;
      if(this.searchForm.sendTime && this.searchForm.sendTime.length > 0) {
        [this.searchForm.sendStartTime, this.searchForm.sendEndTime] = this.searchForm.sendTime;
      }else {
        this.searchForm.sendStartTime = "";
        this.searchForm.sendEndTime = "";
      }
      if (this.searchForm.useTime && this.searchForm.useTime?.length > 0) {
        [this.searchForm.useStartTime, this.searchForm.useEndTime] = this.searchForm.useTime;
      }else {
        this.searchForm.useStartTime = "";
        this.searchForm.useEndTime = "";
      }
      this.$refs?.page?.getServerList('/hm/hmCoupon/sendCodeDetailList',this.searchForm).then(res => {
        this.tableData = res.rows.map(item => {
          item._useStatus = item.useStatus === 1 ? '已使用' : item.useStatus === 0 ? '已过期' : item.useStatus === 2 ? '已取消' : '未使用'
          item.amount = item.amount + '元'
          return item
        })
      }).finally(() => {this.isTableLoading = false});
    },
    closeModal() {
      this.isOpenModal = false
    }
  }
}
</script>
<style scoped lang="scss">

</style>